import {addClass,removeClass, hasClass,findIn } from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
export function init() {
    onFind('.js-anchor-nav-area', (base,) => {
        let anchorNavItem = findIn('.js-anchor-nav-area__item', base);
        if(!anchorNavItem){
            addClass('d-none', base);
        }else if (anchorNavItem && hasClass('d-none', base)){
            removeClass('d-none', base);
        }
    });
}