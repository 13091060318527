import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, findIn, on, addClass, removeClass, find, hasClass, trigger} from "@elements/dom-utils";
import {onEnterViewPort} from "@elements/viewport-utils";
import axios from "axios";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import asyncAppend from "@elements/async-append";


export function init() {
    onFind('.js-nav', (nav) =>{
        let navItems = findAllIn('.js-nav__item', nav);
        let mainContent = find('#main-content');

        navItems.map((navItem) => {
            let toggle = findIn('.js-nav__item-toggle', navItem);
            let overlay = findIn('.js-nav__item-overlay', navItem);

            if(toggle && overlay){
                if(window.matchMedia("(min-width: 1200px)").matches){

                    let timeout = 150;
                    let timer;

                    on('mouseover', (e) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            addClass('is-active', overlay);
                            addClass('is-active', toggle);
                            if(hasClass('js-nav__item--main', navItem)){
                                addClass('has-overlay', mainContent);
                            }
                        }, timeout);

                    }, navItem);

                    on('mouseleave', (e) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            removeClass('is-active', overlay);
                            removeClass('is-active', toggle);

                            if(hasClass('js-nav__item--main', navItem)){
                                removeClass('has-overlay', mainContent);
                            }
                        }, timeout);
                    }, navItem);

                    on('focusin', () => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            addClass('is-active', overlay);
                            addClass('is-active', toggle);
                            if(hasClass('js-nav__item--main', navItem)){
                                addClass('has-overlay', mainContent);
                            }
                        }, timeout);
                    }, navItem);

                    on('focusout', (e) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            removeClass('is-active', overlay);
                            removeClass('is-active', toggle);

                            if(hasClass('js-nav__item--main', navItem)){
                                removeClass('has-overlay', mainContent);
                            }
                        }, timeout);
                    }, navItem);
                }else{
                    let back = findIn('.js-nav__item-back', overlay);

                    on('click', (e) => {
                        e.preventDefault();
                        if(hasClass('is-active', overlay)){
                            removeClass('is-active', overlay);
                            removeClass('is-active', toggle);
                        }else{
                            addClass('is-active', overlay);
                            addClass('is-active', toggle);
                        }
                    }, toggle);

                    if(back){
                        on('click', (e) => {
                            removeClass('is-active', overlay);
                            removeClass('is-active', toggle);
                        }, back);
                    }

                    on('overlay:close', (e) => {
                        let toggles = findAllIn('.js-nav__item-toggle', nav);
                        let overlays = findAllIn('.js-nav__item-overlay', nav);

                        toggles.map((toggle) => {
                            removeClass('is-active', toggle);
                        });
                        overlays.map((overlay) => {
                            removeClass('is-active', overlay);
                        });
                    }, document.body);
                }
            }
        });

    })
    // when user is logged in, load and show user
    onFind('.js-nav-user', (user) =>{
        let dataset = getPrefixedDataSet('async-append-viewport', user);
        let target;


        target = findAllIn('.js-nav-user__result', user);

        onEnterViewPort(user, () => {
            let pendingRequest = axios({method: 'POST', url: dataset.url, headers: {'X-Requested-With': 'XMLHttpRequest'} });

            asyncAppend({
                target: target,
                loading: findAllIn('.js-async-append-viewport__loading', user)
            }, pendingRequest.then((x) => {
                trigger('ASYNC.APPEND', document.body);
                return x.data;
            }));
        });
    });
}