import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, on, trigger, findIn, addClass, find} from '@elements/dom-utils';

export function init() {
    onFind('.js-search__toggle', (toggle) => {
        let search = find('.js-search'),
            searchForm = find('.js-search__form');

        if(search){
            let input = findIn('.js-search__input', search);
            let deleteButton = findIn('.js-search__delete', search);
            let recommendations = findAllIn('.js-search__recommendation', search)

            on('click', (e) => {
                input.focus();
            }, toggle);
            
            on('input', (e) => {
                if(input.value){
                    addClass('is-active', deleteButton);
                }else{
                    removeClass('is-active', deleteButton);
                }
            }, input);

            on('click', (e) => {
                input.value = null;
                trigger('input', input);
            }, deleteButton);

            recommendations.map((recommendation) => {
                on('click', (e) => {
                    input.value = recommendation.innerText;
                    trigger('input', input);
                    searchForm.submit();
                }, recommendation);
            });
        }
    });
}