"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,closest,addClass,hasClass, find, findAll} from '@elements/dom-utils';

export function init () {
    onFind('.js-nav-active-state', function (baseElement) {
        // current Link
        let url = window.location.pathname;
        let link = findIn('a.js-nav-active-state__link[href="'+url+'"]', baseElement);
        if(link){
            checkLinks(link);
        }

        // main Link => level 0
        let mainLink = findIn('.js-nav__item--main.is-active-url .main-nav__link--level-0', baseElement);
        if(mainLink){
            addClass('is-active-url', mainLink);
        }

    });
}

function checkLinks(link){
    addClass('is-active-url', link);
    addClass('is-active', link);
    let level = closest('.js-nav__item--main', link);
    if(level){
        addClass('is-active-url', level);

        let level2 = closest('.main-nav__level-2', link);
        if(level2){
            addClass('is-active', level2);
            addClass('is-active', level2.previousElementSibling);
        }
    }
}