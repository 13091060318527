import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';
export function init() {
    onFind('.js-info-modal', modal => {
        let modalInit = new Modal(modal);

        if(!sessionStorage.getItem('infoModalShown')){
            modalInit.show();
            sessionStorage.setItem('infoModalShown', 'true');
        }
    });
}

