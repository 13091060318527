import {onFind} from "@elements/init-modules-in-scope";
import {on, trigger, find} from "@elements/dom-utils";

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import {findAllIn} from "@elements/dom-utils";

import * as tracking from "@elements/tracking";
let trackingOptions = {}; // optional options
tracking.initGtmEventTracking(trackingOptions); // for GTM tracking

import * as formTracking from './tracking/form-tracking';
formTracking.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});
import * as hashCash from './hashCash';
// hashCash.init();

import * as toc from '@elements/toc';
toc.init({linkClass: 'btn btn-primary toc__link visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as reloadForm from '@elements/reload-form';
reloadForm.init();

import * as overlay from './overlay';
overlay.init();

import * as nav from './nav';
nav.init();

import * as navActiveState from './nav-active-state';
navActiveState.init();

import * as search from './search';
search.init();


import * as spriteIcons from './sprite-icons';

import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});


import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger: {event: 'false'},
});
onFind('.js-form-validation.js-ajax-form__form', element => {
    on('form-validation.submit', (e) => {
        element.dispatchEvent(new Event('submit', {bubbles: true, cancelable: true}))
    }, element);

});

import Alert from 'bootstrap/js/dist/alert';

// Todo: Add common JS that is shared between all page types here

import * as renderTemplate from './render-template';
renderTemplate.init();

// if you use this, also use the associated CSS (content-visibility.scss)
import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init({
       cmpTool: 'usercentrics',
        revalidateEvent: 'ucEvent',
    },
    {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn'
    });

// import * as consentOverlay from './consent-overlay';
// consentOverlay.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as video from './video';
video.init();

import * as anchorNav from '@elements/anchor-nav';
anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="nav-item js-anchor-nav-area__item anchor-nav__item list-inline-item"><a class="nav-link js-smoothscroll__link d-flex align-center" data-scroll-offset="180" href="#' + anchor + '"><span class="icon icon-anchor text-secondary me-1" aria-hidden="true"></span><span class="anchor-nav__item-title">' + text + '</span></a></li>';
        }
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as anchorNavArea from './anchor-nav-area';
anchorNavArea.init();

