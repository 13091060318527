
//todo check if loading spinner and posterlement is needed
import {findAll, on, addClass, hasClass, removeClass, findIn, setAttribute, removeAttribute} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {

    onFind('.js-video', (videoContainer) => {
        let video = findIn('.js-video__video', videoContainer);
        if(video) {
            let videoControls = findIn('.js-video--controls', videoContainer);
            if(videoControls){
                removeAttribute('controls', videoControls );
            }
            let playButton = findIn('.js-video__play', videoContainer),
                // videoLoading = findIn('.js-video__loading', videoContainer),
                posterElement = findIn('.js-video__poster', videoContainer);

            if (videoContainer.getAttribute('data-video-url')) {
                const loadVideo = () => {
                    // removeAttribute('hidden', videoLoading);
                    video.src = videoContainer.getAttribute('data-video-url');

                    video.onload = function (){
                        addClass('is-playing', videoContainer);
                        // setAttribute('hidden', true, videoLoading);
                    }
                };

                on('click', () => loadVideo(), playButton);

                if (posterElement) {
                    on('click', () => loadVideo(), posterElement);
                }
            } else {
                const playVideo = () => {
                    // removeAttribute('hidden', videoLoading);
                    console.log('video', video);
                    video.play();
                };

                on('playing', function () {
                    addClass('is-playing', videoContainer);
                    // setAttribute('hidden', true, videoLoading);
                    if(videoControls){
                        setAttribute('controls', true, videoControls );
                    }
                }, video);

                on('pause', function () {
                    removeClass('is-playing', videoContainer);
                    if(videoControls){
                        removeAttribute('controls', videoControls );
                    }
                }, video);

                on('ended', function () {
                    removeClass('is-playing', videoContainer);
                    if(videoControls){
                        removeAttribute('controls', videoControls );
                    }
                    addClass('is-ended', videoContainer)
                }, video);

                if(playButton != null){
                    playButton.addEventListener('click', () => {
                        playVideo();
                    });
                    if (posterElement) {
                        posterElement.addEventListener('click', () => {
                            playVideo();
                        });
                    }
                }

                if(window.matchMedia("(max-width: 767px)").matches){
                    let touchEvent = 'ontouchstart' in window ? 'touchstart' : 'click';

                    video.addEventListener(touchEvent, () => {
                        if(hasClass('is-playing', videoContainer)){
                            video.pause();
                        }else{
                            playVideo();
                        }
                    });
                }
            }
        };

    });

}